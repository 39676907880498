<template>
  <div>
    <div style="padding-top: 30px;">
      <el-steps
        :active="3"
        align-center
      >
        <el-step title="填写商家信息"></el-step>
        <el-step title="填写店铺信息"></el-step>
        <el-step title="填写收款账户信息"></el-step>
        <el-step title="开始使用"></el-step>
      </el-steps>
    </div>

    <div class="mine">
      <SupplybankAudit />
    </div>
  </div>
</template>

<script>
import SupplybankAudit from './supplybank'
export default {
  components: {
    SupplybankAudit
  }
}
</script>

<style lang="scss" scope>
.mine {
  padding: 20px;
}
</style>
